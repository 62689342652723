// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .custom-home-banner_YKJTf{position:relative;height:232px;overflow:hidden;border-radius:5px}html.app__layout--desktop .custom-home-banner__image_w4j4h{width:100%;height:100%}html.app__layout--desktop .custom-home-banner__info_UAHih{position:absolute;top:32px;bottom:16px;display:flex;flex-direction:column;gap:12px;width:274px;color:var(--DTextDefault);text-align:center;left:32px}html.app__layout--desktop .custom-home-banner__button_VshNY{width:100%;margin:auto auto 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-home-banner": `custom-home-banner_YKJTf`,
	"custom-home-banner__image": `custom-home-banner__image_w4j4h`,
	"custom-home-banner__info": `custom-home-banner__info_UAHih`,
	"custom-home-banner__button": `custom-home-banner__button_VshNY`
};
export default ___CSS_LOADER_EXPORT___;
