import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { CustomerBetType, lobbyTypeHome } from '@leon-hub/api-sdk';
import RouteName, { CasinoRouteName } from '@leon-hub/routing-config-names';
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import ShowcaseNavigationRouteComponent from 'web/src/modules/home/submodules/showcase/ShowcaseNavigationRouteComponent.vue';
import CustomHomepageBanners from 'web/src/modules/banners/components/CustomHomepageBanners/CustomHomepageBanners.vue';
import { useCustomerBetTypeStore } from 'web/src/modules/customer/store';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomHomeRoutePage',
    setup (__props) {
        useRouter();
        toRef(useCustomerBetTypeStore(), 'betType');
        const egsSkeletons = toRef(useSiteConfigStore(), 'egsSkeletons');
        const skeletons = computed(()=>egsSkeletons.value?.home ?? []);
        useKeepAlive();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['custom-home'])
            }, [
                _createVNode(CustomHomepageBanners, {
                    class: _normalizeClass(_ctx.$style['custom-home__banners'])
                }, null, 8, [
                    "class"
                ]),
                _createVNode(ShowcaseNavigationRouteComponent, {
                    "is-custom-homepage": "",
                    class: _normalizeClass(_ctx.$style['custom-home__showcase'])
                }, null, 8, [
                    "class"
                ]),
                _createVNode(_unref(LobbyBlock), {
                    "lobby-type": _unref(lobbyTypeHome),
                    skeletons: skeletons.value,
                    "prevent-items-fetch": "",
                    "is-custom-homepage": "",
                    "is-full-height": ""
                }, null, 8, [
                    "lobby-type",
                    "skeletons"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomHomeRoutePage'
                ]
            ]);
        };
    }
});
