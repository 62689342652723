import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import useMainBannerSectionRouteComponent from 'web/src/modules/banners/components/MainBannerSectionRouteComponent/composables/useMainBannerSectionRouteComponent';
import { BannerLocation } from 'web/src/modules/banners/enums';
import CustomHomepageBanner from 'web/src/modules/banners/components/CustomHomepageBanner/CustomHomepageBanner.vue';
import VFadeCarousel from 'web/src/components/FadeCarousel/VFadeCarousel/VFadeCarousel.vue';
import VFadeCarouselSlide from 'web/src/components/FadeCarousel/VFadeCarouselSlide/VFadeCarouselSlide.vue';
import VFadeCarouselPointsPagination from 'web/src/components/FadeCarousel/VFadeCarouselPointsPagination/VFadeCarouselPointsPagination.vue';
import VFadeCarouselNavigationButtons from 'web/src/components/FadeCarousel/VFadeCarouselNavigationButtons/VFadeCarouselNavigationButtons.vue';
import { PaginationLocation } from 'web/src/components/FadeCarousel/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomHomepageBanners',
    setup (__props) {
        const { currentGroup } = useMainBannerSectionRouteComponent({
            location: BannerLocation.TOP_CUSTOM_HOME_PAGE
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(currentGroup)?.banners.length ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style['custom-homepage-banners'])
            }, [
                _createVNode(VFadeCarousel, {
                    "autoplay-timeout": _unref(currentGroup).autoplayDelay
                }, {
                    pagination: _withCtx(()=>[
                            (_openBlock(), _createBlock(VFadeCarouselNavigationButtons, {
                                key: 0
                            })),
                            _createVNode(VFadeCarouselPointsPagination, {
                                "pagination-location": _unref(PaginationLocation).RIGHT
                            }, null, 8, [
                                "pagination-location"
                            ])
                        ]),
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentGroup).banners, (banner, index)=>(_openBlock(), _createBlock(VFadeCarouselSlide, {
                                    key: banner.content.image.src,
                                    "current-slide-index": index
                                }, {
                                    default: _withCtx(()=>[
                                            _createVNode(CustomHomepageBanner, {
                                                banner: banner.content
                                            }, null, 8, [
                                                "banner"
                                            ])
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "current-slide-index"
                                ]))), 128))
                        ]),
                    _: 1
                }, 8, [
                    "autoplay-timeout"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomHomepageBanners'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
