// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .custom-home_fL7L2{width:100%;min-width:768px;max-width:1440px;margin:0 auto;padding:0 16px;overflow:hidden}html.app__layout--desktop .custom-home__banners_et7RQ{margin-bottom:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-home": `custom-home_fL7L2`,
	"custom-home__banners": `custom-home__banners_et7RQ`
};
export default ___CSS_LOADER_EXPORT___;
