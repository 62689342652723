// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .egs-game-jackpot-list-item_GtAKj{display:flex;flex-direction:column;gap:8px;width:320px;-webkit-text-decoration:none;text-decoration:none;cursor:pointer}html.app__layout--desktop .egs-game-jackpot-list-item__container_pk4oY{position:relative;width:100%;height:160px;overflow:hidden;border-radius:5px}html.app__layout--desktop .egs-game-jackpot-list-item__counter-container_DXhmQ{position:absolute;right:0;bottom:0;left:0;text-align:center}html.app__layout--desktop .egs-game-jackpot-list-item__label_Po0Lq{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;color:var(--TextDefault);text-align:center}html.app__layout--desktop .egs-game-jackpot-list-item__img_uOyzq{-webkit-user-select:none;user-select:none;-webkit-user-drag:none;transition:transform .5s ease;transform:scale(1)}html.app__layout--desktop .egs-game-jackpot-list-item_GtAKj:hover .egs-game-jackpot-list-item__label_Po0Lq{color:var(--BrandDefault)}html.app__layout--desktop .egs-game-jackpot-list-item_GtAKj:hover .egs-game-jackpot-list-item__img_uOyzq{transition:transform .5s ease;transform:scale(1.05)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"egs-game-jackpot-list-item": `egs-game-jackpot-list-item_GtAKj`,
	"egs-game-jackpot-list-item__container": `egs-game-jackpot-list-item__container_pk4oY`,
	"egs-game-jackpot-list-item__counter-container": `egs-game-jackpot-list-item__counter-container_DXhmQ`,
	"egs-game-jackpot-list-item__label": `egs-game-jackpot-list-item__label_Po0Lq`,
	"egs-game-jackpot-list-item__img": `egs-game-jackpot-list-item__img_uOyzq`
};
export default ___CSS_LOADER_EXPORT___;
