import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { Tag } from '@leon-hub/tags';
import { ButtonKind } from '@leon-hub/module-buttons';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { ObjectFitOption, ObjectPosition } from 'web/src/components/Image/VImage/enums';
import { useBannerLocation, useBannerTexts } from 'web/src/modules/banners/components/composables';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomHomepageBanner',
    props: {
        banner: {}
    },
    setup (__props) {
        const props = __props;
        const bannerRef = toRef(props, 'banner');
        const { buttonText } = useBannerTexts(bannerRef);
        const { href, onClick } = useBannerLocation(bannerRef);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['custom-home-banner'])
            }, [
                _ctx.banner.image ? (_openBlock(), _createBlock(VImage, _mergeProps({
                    key: 0
                }, _ctx.banner.image, {
                    "object-fit": _unref(ObjectFitOption).COVER,
                    "object-position": _unref(ObjectPosition).LEFT,
                    class: _ctx.$style['custom-home-banner__image']
                }), null, 16, [
                    "object-fit",
                    "object-position",
                    "class"
                ])) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['custom-home-banner__info'])
                }, [
                    _unref(buttonText) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['custom-home-banner__button'])
                    }, [
                        _createVNode(VButton, {
                            label: _unref(buttonText),
                            kind: _unref(ButtonKind).PRIMARY,
                            href: _unref(href),
                            tag: _unref(Tag).A,
                            onClick: _withModifiers(_unref(onClick), [
                                "stop",
                                "prevent"
                            ])
                        }, null, 8, [
                            "label",
                            "kind",
                            "href",
                            "tag",
                            "onClick"
                        ])
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CustomHomepageBanner'
                ]
            ]);
        };
    }
});
